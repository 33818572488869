import React, { useEffect, useRef, createElement, Fragment, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { initializeAlgoliaClient } from '../algolia_listing_pages/utils/algolia_client';
import { autocomplete, getAlgoliaResults, getAlgoliaFacets } from '@algolia/autocomplete-js';
import AlgoliaIndexMap from 'algolia_listing_pages/constants/AlgoliaIndexMap';

// custom renderers of results
import FacetResult from './../autocomplete/result-renderers/FacetResult.jsx';
import StyleResult from './../autocomplete/result-renderers/SimpleStyleResult.jsx';

// algolia plugins
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { handleAutocompleteSelection, passThruSearchParams } from 'algolia_listing_pages/utils/autoCompleteHelpers';

import { getOctoIdFromCookie } from '../algolia_listing_pages/utils/data_utils.js';

const Autocomplete = (props) => {
  const railsEnv = props.railsEnv || props.rails_env || 'staging';
  const searchParams = new URLSearchParams(document.location.search);
  const iframed = searchParams.get('iframer') || false;
  const compatibleTypeFilter = searchParams.get('compatible_type') || props.compatibleTypeFilter || '';
  const indexName = AlgoliaIndexMap[railsEnv] + '_styles';
  const suggestedQueriesIndexName = AlgoliaIndexMap[railsEnv] + '_styles_query_suggestions';
  const containerRef = useRef(null);
  const panelRootRef = useRef(null);
  const rootRef = useRef(null);
  const useDetachedMode = props.detachedMode || false;
  const useInsights = props.insights || true;
  const populateOnEmpty = props.populateOnEmpty || false;
  const useRecentSearches = compatibleTypeFilter === '';
  const useQuerySuggestions = compatibleTypeFilter === '';
  const placeholderText = props.placeholder || 'Search for t-shirts, hoodies, koozies...';
  const emptyQueryLegend = props.emptyQueryLegend || '';
  const showFacetCounts = props.showFacetCounts || false;
  const hitsToShow = props.hitsToShow || 6;
  const searchClient = props.algoliaClient || initializeAlgoliaClient();
  let facets = props.facets;
  if (compatibleTypeFilter !== '') facets = [];
  const [isSearchInHeaderTest,setIsSearchInHeaderTest] = useState(Signalman.featureFlagFromCookie('search_header_v4') == 'test' || false);
  // PLUGINS
  const plugins = [];
    const handleCiHeaderLoaded = () => {
      if (iframed) return setIsSearchInHeaderTest(false);
      const isInTestGroup =
        Signalman.featureFlagFromCookie('search_header_v4') == 'test';
        if (isInTestGroup) {
          document.querySelector('.pc-SearchTarget').style.display = 'none';
        }
      setIsSearchInHeaderTest(isInTestGroup);
    };
    useEffect(() => {
      window.addEventListener('ciHeaderLoaded', handleCiHeaderLoaded);
      return () => {
        window.removeEventListener('ciHeaderLoaded', handleCiHeaderLoaded);
      };
    }, []);

  if (useRecentSearches) {
    const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
      key: 'ALG_CAT_RECENT_SEARCH',
      limit: 6,
      transformSource({ source }) {
        return {
          ...source,
          onSelect: ({item}) => handleAutocompleteSelection(searchClient, indexName, item.label),
          templates: {
            header () {
              return (<>
                <span className="aa-SourceHeaderTitle">Recent Searches</span>
              </>);
            },
            ...source.templates,
          }
        };
      },
    });
    plugins.push(recentSearchesPlugin);
  }

  if (useQuerySuggestions) {
    const querySuggestionsPlugin = createQuerySuggestionsPlugin({
      searchClient,
      indexName: suggestedQueriesIndexName,
      getSearchParams({ state }) {
        return {
          hitsPerPage: state.query? 6 : 4,
          clickAnalytics: useInsights,
        };
      },
      transformSource({ source }) {
        return {
          ...source,
          onSelect({ item }) {
            window.location = passThruSearchParams(`/products/results?utf8=✓&keyword=${encodeURIComponent(item.query)}&searchType=catalog`);
          },
          templates: {
            header () {
              return (<>
                <span className="aa-SourceHeaderTitle">Suggestions</span>
              </>);
            },
            ...source.templates,
          }
        };
      },
    });
    plugins.push(querySuggestionsPlugin);
  }

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    document.body.classList.add('autocomplete-theme');

    const search = autocomplete({
      container: containerRef.current,
      insights: useInsights,
      renderer: { createElement, Fragment, render: () => {} },
      render ({ children }, root) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root;
          panelRootRef.current?.unmount();
          panelRootRef.current = createRoot(root);
        }
        panelRootRef.current.render(children);
      },
      placeholder: placeholderText,
      openOnFocus: true,
      detachedMediaQuery: useDetachedMode ? '' : null,
      getSources ({ query }) {
        let mappedFilters = '(status:active)';
        let facetFilters = [];
        if (compatibleTypeFilter) facetFilters.push(`compatible_type:${compatibleTypeFilter}`);

        const sources = [];

        facets.forEach((facet) => {
          sources.push({
            sourceId: `facet_${facet.name}`,
            getItems({ query }) {
              if (!populateOnEmpty && !query) return [];
              return getAlgoliaFacets({
                searchClient,
                queries: [
                  {
                    indexName: indexName,
                    facet: facet.name,
                    params: {
                      facetQuery: query,
                      maxFacetHits: facet.showNum || 6,
                      clickAnalytics: useInsights,
                    },
                  },
                ],
              });
            },
            templates: {
              header ({ items }) {
                if (!items.length) return null;
                return (<>
                  <span className="aa-SourceHeaderTitle">{facet.caption}</span>
                </>);
              },
              item({ item, components }) {
                return <FacetResult hit={item} components={components} showCounts={showFacetCounts} />;
              },
            },
            onSelect: ({item}) => handleAutocompleteSelection(searchClient, indexName, item.label),
          });
        });

        sources.push({
          sourceId: 'styles',
          getItems() {
            if (!populateOnEmpty && !query) return [];
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: indexName,
                  query,
                  params: {
                    hitsPerPage: hitsToShow,
                    filters: mappedFilters,
                    facetFilters: facetFilters,
                    clickAnalytics: useInsights,
                  },
                },
              ],
            });
          },
          templates: {
            header ({ items }) {
              if (!items.length) return null;
              return (<>
                <span className="aa-SourceHeaderTitle">Results for Styles</span>
              </>);
            },
            item({ item, components }) {
              return <StyleResult hit={item} components={components} />;
            },
            footer () { },
            noResults () {
              if (!query && iframed) return 'Start typing for results.';
              if (!query) return emptyQueryLegend || '';
              return 'No results.'
            }
          },
          onSelect({ item }) {
            let path = '';
            if (item.target_url) path = item.target_url;
            if (item.breadcrumbs && item.breadcrumbs.length) {
              path = item.breadcrumbs[item.breadcrumbs.length - 1].path;
            }
            window.location = passThruSearchParams(path);
          },
        });

        return sources;
      },
      plugins: plugins,
      initialState: {
        query: new URL(window.location).searchParams.get('keyword') || '',
      },
      ...props,
    });

    const detachedTrigger = document.querySelector('.pc-SearchTarget');
    if (detachedTrigger) {
      detachedTrigger.addEventListener('click', () => {
        search.setIsOpen(true);
        const el = document.querySelector('.aa-Input');
        if (el) {
          el.setAttribute('autofocus', 'autofocus');
          el.focus();
        }
      });
    }
    $(document).on('keypress', '.aa-Input', (evt) => {
      if (evt.keyCode === 13) {
        const qry = $('.aa-Input').val();
        if (qry.trim() === '') return;
         handleAutocompleteSelection(searchClient, indexName, qry.trim());
      }
      if (evt.key === ' ') {
        const el = $('.aa-Input');
        let qry = el.val() + evt.key;
        // remove 2+ spaces
        while (qry.indexOf('  ') >= 0) {
          qry = qry.replace(/  /g, ' ');
        }
        if (qry.endsWith(' ')) qry = qry.substring(0, qry.length - 1);
        el.val(qry);
      }
    });

    return () => {
      search.destroy();
    };
  }, [props,isSearchInHeaderTest]);

  const oeUserToken = getOctoIdFromCookie();
  if (typeof window.aa === 'function' && oeUserToken) {
    window.aa('setUserToken', oeUserToken)
  }

  return (
    <>
      {!isSearchInHeaderTest && (
        <div className="pc-ToolBar-autocomplete">
          <div ref={containerRef} />
        </div>
      )}
    </>
  );
}

export default Autocomplete;
