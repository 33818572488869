import PropTypes from 'prop-types';
import React from 'react';

function Rating(props) {
  const {generalComments, name, ratingCount, ratingScore} = props;

  const styleRatingScoreData = () => {
    let score = Number(ratingScore).toFixed(1);
    if (score.endsWith('0')) {
      score = parseInt(score).toString();
    }
    return score;
  };

  const styleRatingCount = () => {
    let capped = false;
    let count = ratingCount;
    const capLimit = 10000;
    if (count > capLimit) {
      count = capLimit;
      capped = true;
    }
    let delimitedRatingCount = count.toLocaleString();
    if (capped) {
      delimitedRatingCount += '+';
    }
    return `(${delimitedRatingCount})`;
  };

  const structuredData = () => {
    const data = {
      '@context': 'https://schema.org',
      '@type': 'Product',
      aggregateRating: {
        '@type': 'AggregateRating',
        bestRating: '5',
        ratingCount: `${ratingCount}`,
        ratingValue: ratingScore
      },
      description: generalComments,
      name
    };

    return JSON.stringify(data);
  };

  return (
    <div className="pc-ProductCard-detailRating">
      <script type="application/ld+json">{structuredData()}</script>
      <div className="pc-Rating-stars" data-value={styleRatingScoreData()} />
      <div className="pc-Rating-count">{styleRatingCount()}</div>
    </div>
  );
}

Rating.propTypes = {
  generalComments: PropTypes.string,
  name: PropTypes.string,
  ratingCount: PropTypes.number,
  ratingScore: PropTypes.string
};

export default Rating;
