import React from 'react';
import PropTypes from 'prop-types';
import { useInstantSearch } from 'react-instantsearch';
import $ from 'jquery';
import FilterGroup from './FilterGroup';
import ColorFilterGroup from './ColorFilterGroup';
import PriceLevel from './PriceLevel';
import IndividualShipEligible from './IndividualShipEligible';
import MinimumQuantity from './MinimumQuantity';
import useWindowDimensions from './useWindowDimensions';
import Specialty from './internal/Specialty';
import Halftones from './internal/Halftones';
import InternationalShipEligible from './internal/InternationalShipEligible';
import InternalFilterGroup from './internal/InternalFilterGroup';
import MinOrderQty from './internal/MinOrderQty';
import CrossSubcategoryNavigation from "./CrossSubcategoryNavigation";
import RushDeliveryTurntimesFilterGroup from './RushDeliveryTurntimesFilterGroup';
import { collapsedFilters } from '../../utils/constants/signalmanConstants';

const DynamicRefinementsList = (props) => {
  // More filter groups may need to be discovered
  const filterGroupMap = {
    'colors': 'colors.simple_names',
    'brand': 'fg-brand',
    'min_qty': 'has_singles_enabled_colors',
    'subcat_nav': 'fg-subcat_nav',
  }

  const internalFilters = [
    'specialty', 'halftones', 'international_ship_eligible', 'brand', 'broad_category_names',
    'sub_category_names', 'price_level', 'desired_quantity', 'search_data_material', 'min_qty',
    'min_order_qty', 'no_minimum', 'rush_turn_time', 'sizes', 'decoration_method', 'turn_time',
    'weight'
  ];

  const internalFilterGroupMap = {
    'material': 'search_data_material',
    'decoration': 'decoration_method'
  }

  const sortByNameFilterGroups = ['brand'];
  const facetLimit = 100;
  const { results } = useInstantSearch(props);
  const {
          filterGroups,
          refinementItems,
          filterValues,
          internalCatalog,
          currentUserInternal,
          filterGroupList,
          subcategoryNavigation,
        } = props;
  const filterGroupToFacetMap = filterGroups.reduce((acc, v) => ({
    ...acc,[v]: filterGroupMap[v] || v }), {});
  const { width } = useWindowDimensions();

  const filterChangeHandler = () => {
    if(width < 700){
      $('.pc-Filters').delay(500).fadeToggle();
    }
  }
  const sortByName = (filterGroup) => {
    return sortByNameFilterGroups.includes(filterGroup);
  }

  const isCollapsedFilter = !currentUserInternal && !internalCatalog && collapsedFilters === 'test'

  const renderFilterGroups = () => {
    try {
      // Check if Algolia is loaded with below line
      Object.keys(results['_rawResults'][0]['facets']);
      return filterGroups?.map(filterGroup => {
        if (filterGroup === 'colors') {
          return (
            <React.Fragment key={filterGroupToFacetMap[filterGroup]}>
              <ColorFilterGroup
                attribute={filterGroupToFacetMap[filterGroup]}
                limit={facetLimit}
                filterChangeHandler={() => filterChangeHandler()}
              />
              <ColorFilterGroup
                attribute="min_qty_colors.simple_names"
                limit={facetLimit}
                filterChangeHandler={() => filterChangeHandler()}
                operator="and"
              />
            </React.Fragment>
          );
        } else if (filterGroup === 'price_level') {
          return (
          <PriceLevel
            attribute={filterGroupToFacetMap[filterGroup]}
            key={filterGroupToFacetMap[filterGroup]}
            limit={facetLimit}
            sortBy={['name']}
            filterChangeHandler={() => filterChangeHandler()}
            isCollapsedFilter={isCollapsedFilter}
          />
          )
        } else if (filterGroup === 'individual_ship_eligible') {
          return (
            <IndividualShipEligible
              attribute={filterGroupToFacetMap[filterGroup]}
              key={filterGroupToFacetMap[filterGroup]}
              filterChangeHandler={() => filterChangeHandler()}
              isCollapsedFilter={isCollapsedFilter}
            />
          )
        } else if(filterGroup === 'min_qty') {
          return (
            <MinimumQuantity
              attribute={filterGroupToFacetMap[filterGroup]}
              key={filterGroupToFacetMap[filterGroup]}
              filterChangeHandler={() => filterChangeHandler()}
            />
          )
        } else if(filterGroup === 'rush_delivery_turntimes') {
          return (
            <RushDeliveryTurntimesFilterGroup
              attribute={filterGroupToFacetMap[filterGroup]}
              key={filterGroupToFacetMap[filterGroup]}
              isCollapsedFilter={isCollapsedFilter}
            />
          )
        } else if(filterGroup === 'compatible_type') {
          return null
        } else {
          const attribute = filterGroupToFacetMap[filterGroup]
          return (
            <FilterGroup
              key={attribute}
              attribute={attribute}
              limit={99}
              showMore={true}
              showMoreLimit={facetLimit}
              sortBy={sortByName(filterGroup) ? ['name'] : ['count']}
              filterChangeHandler={() => filterChangeHandler()}
              values={filterValues ? filterValues[attribute] : undefined}
              sizeOrder={props.sizeOrder}
              filterValuesOrder={props.filterValuesOrder}
              filterGroupList={filterGroupList}
              isCollapsedFilter={isCollapsedFilter}
            />
          )
        }
      });
    } catch (error) {
      console.log('No results from Algolia');
    }
  }

  const renderInternalFilterGroups = ()  => {
    try {
      // Check if Algolia is loaded with below line
      Object.keys(results['_rawResults'][0]['facets']);
      return internalFilters.filter((intFilter) => !filterGroups.includes(intFilter)).map(internalFilter => {
        if (internalFilter === 'specialty') {
          return(
            <Specialty
              attribute={internalFilter}
              key={`internal-${internalFilter}`}
              internalCatalog={internalCatalog}
              filterChangeHandler={() => filterChangeHandler()}
            />
          )
        } else if (internalFilter === 'halftones') {
          return(
            <Halftones
              attribute={internalFilter}
              key={`internal-${internalFilter}`}
              internalCatalog={internalCatalog}
              filterChangeHandler={() => filterChangeHandler()}
            />
          )
        } else if (internalFilter === 'international_ship_eligible') {
          return(
            <InternationalShipEligible
              attribute={internalFilter}
              key={`internal-${internalFilter}`}
              internalCatalog={internalCatalog}
              filterChangeHandler={() => filterChangeHandler()}
            />
          )
        } else if (internalFilter === 'min_order_qty') {
          return(
            <MinOrderQty
              attribute={internalFilter}
              key={`internal-${internalFilter}`}
              internalCatalog={internalCatalog}
              filterChangeHandler={() => filterChangeHandler()}
              quantity={props.quantity}
              quoteQuantityDirty={props.quoteQuantityDirty}
              handleQuantityChange={props.handleQuantityChange}
              onRemoveOrderSize={props.onRemoveOrderSize}
              algoliaIndex={props.algoliaIndex}
            />
          )
        } else {
          return (
            <InternalFilterGroup
              attribute={internalFilterGroupMap[internalFilter] || internalFilter}
              key={`internal-${internalFilter}`}
              internalCatalog={internalCatalog}
              limit={7}
              showMore={true}
              showMoreLimit={facetLimit}
              filterChangeHandler={() => filterChangeHandler()}
            />
          )
        }
      })
    } catch (error) {
      console.log('No results from Algolia');
    }
  }

  return(
    <div className='pc-Filters'>
      <header className='pc-Filters-header'>
        {`Filters ${refinementItems.length > 0 ? `(${refinementItems.length})` : ''}`}
        <span className='pc-Filters-headerClose'></span>
      </header>
      <div className='pc-Filters-body'>
        <CrossSubcategoryNavigation
          attribute={filterGroupToFacetMap['subcat_nav']}
          key={filterGroupToFacetMap['subcat_nav']}
          subcategoryNavigation={subcategoryNavigation}
        />
        {renderFilterGroups()}
        {currentUserInternal && internalCatalog && renderInternalFilterGroups()}
      </div>
      <header className="pc-Filters-footer">Apply</header>
    </div>
  )

}

DynamicRefinementsList.propTypes = {
  filterGroups: PropTypes.array
}

export default DynamicRefinementsList;
