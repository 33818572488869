import React, {useState, useEffect, useRef} from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import PropTypes from 'prop-types';
import {initializeAlgoliaClient} from '../algolia_listing_pages/utils/algolia_client';
import {InstantSearch, Configure} from 'react-instantsearch';
import {history} from 'instantsearch.js/es/lib/routers';
import ListingsPage from '../algolia_listing_pages/ListingsPage';
import {
  getCatalogToAlgoliaSortMap,
  getAlgoliaToCatalogSortMap,
  getCatalogToAlgoliaSortMapSimplified,
  getAlgoliaToCatalogSortMapSimplified
} from '../algolia_listing_pages/sort/sortHelpers';
import {Provider} from '@rollbar/react';
import ErrorBoundary from './ErrorBoundary';
import {PigmentThemeProvider} from '@customink/pigment-react';
import {refinementListToSearchMap} from '../algolia_listing_pages/utils/refinement_utils';
import {
  getPageViewEvent,
  getOctoIdFromCookie
} from '../algolia_listing_pages/utils/data_utils';
import {
  setQueryStringParams,
  deleteQueryStringParams,
  checkAnyQueryParams
} from '../algolia_listing_pages/utils/route_utils';
import {handleQuickQuoteVisibility} from '../algolia_listing_pages/utils/quick_quote_utils';
import QuickQuote from '../algolia_listing_pages/internal/QuickQuote';
import Pricing from '../src/shared/pricing';
import Placeholders from '../algolia_listing_pages/Placeholders';
import {
  instantSearchOnStateChange,
  instantSearchRouterHistoryUrl,
  mapRouteToState,
  mapStateToRoute
} from '../algolia_listing_pages/utils/instantsearch_utils';
import rollbar from 'src/vendor/rollbar';
import AlgoliaIndexMap from '../algolia_listing_pages/constants/AlgoliaIndexMap';
import {catalogMat} from '../utils/constants/signalmanConstants';
import { getDefaultQuoteQuantity } from '../algolia_listing_pages/utils/defaultUnitPricing';

const queryClient = new QueryClient();
const algoliaClient = initializeAlgoliaClient();
const oeUserToken = getOctoIdFromCookie();

if (oeUserToken) {
  if (typeof window.aa === 'function') {
    window.aa('setUserToken', oeUserToken);
  }
}

const AlgoliaListingPages = (props) => {
  const {
    rails_env,
    filter_groups,
    controller,
    action,
    default_quote_qty,
    current_user_internal,
    ydh_settings,
    subcategory_navigation,
    design_preview_enabled_category_ids
  } = props;

  const searchParams = new URLSearchParams(document.location.search);
  const [siteWideSearch, setSiteWideSearch] = useState(false);
  const [userQuery, setUserQuery] = useState(searchParams.get('keyword') || '');
  const [quantity, setQuantity] = useState(props.default_quote_qty || '');
  const [frontColor, setFrontColor] = useState(1);
  const [backColor, setBackColor] = useState(0);
  const [quoteQuantityDirty, setQuoteQuantityDirty] = useState(false);
  const [emptyListings, setEmptyListings] = useState(false);
  const [cid, _setCid] = useState(searchParams.get('cid') || '');
  const [digest, _setDigest] = useState(searchParams.get('digest') || '');
  const [iframer, _setIframer] = useState(searchParams.get('iframer') || '');
  const [multiItem, _setMultiItem] = useState(
    searchParams.get('multi_item') || ''
  );
  const [width, setWidth] = useState(window.innerWidth);
  const [isInitialSearch, setIsInitialSearch] = useState(true);

  const isInitialSearchRef = useRef(isInitialSearch);

  const isMobile = width <= 700;
  const algoliaIndexName = AlgoliaIndexMap[rails_env] + '_styles';
  const algoliaIndexPrefix = AlgoliaIndexMap[rails_env];
  const categoryId = props.category_id;
  const pricingAlgorithm = new Pricing().defaultAlgorithm;
  const internalCatalog = catalogMat === 'test' && current_user_internal;

  filter_groups?.map((filterGroup) => {
    if (!Object.values(refinementListToSearchMap).includes(filterGroup)) {
      refinementListToSearchMap[filterGroup] = filterGroup;
    }
  });

  const flipObj = (obj) => {
    let flippedObj = {};
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const element = obj[key];
        flippedObj[element] = key;
      }
    }
    return flippedObj;
  };

  const searchToRefinementListMap = flipObj(refinementListToSearchMap);
  const sortQuantity = getDefaultQuoteQuantity(categoryId, quantity);
  const algoliaToCatalogSort = getAlgoliaToCatalogSortMap(
    algoliaIndexName,
    sortQuantity,
    pricingAlgorithm,
    algoliaIndexPrefix
  );
  const catalogToAlgoliaSort = getCatalogToAlgoliaSortMap(
    algoliaIndexName,
    sortQuantity,
    pricingAlgorithm,
    algoliaIndexPrefix
  );

  const algoliaToCatalogSortSimplified = getAlgoliaToCatalogSortMapSimplified(
    sortQuantity,
    pricingAlgorithm,
    algoliaIndexPrefix
  );
  const catalogToAlgoliaSortSimplified = getCatalogToAlgoliaSortMapSimplified(
    sortQuantity,
    pricingAlgorithm,
    algoliaIndexPrefix
  );

  const routing = {
    router: history({
      createURL({qsModule, routeState, location}) {
        return instantSearchRouterHistoryUrl(
          routeState,
          location,
          searchToRefinementListMap,
          current_user_internal
        );
      }
    }),
    stateMapping: {
      stateToRoute(uiState) {
        return mapStateToRoute(
          uiState,
          algoliaIndexName,
          algoliaToCatalogSort,
          refinementListToSearchMap,
          algoliaToCatalogSortSimplified
        );
      },
      routeToState(routeState) {
        return mapRouteToState(
          routeState,
          algoliaIndexName,
          catalogToAlgoliaSort,
          searchToRefinementListMap,
          catalogToAlgoliaSortSimplified
        );
      }
    }
  };

  const searchClient = {
    ...algoliaClient,
    search(requests) {
      // auto-applies filters for either category page, or search
      let filter =
        requests.every(({params}) => !params.query) && categoryId
          ? `status: active OR status: inactive AND categories.id: ${categoryId}`
          : 'status: active OR status: inactive AND NOT metadata.STYLES_TARGET_CATEGORY_PROMOTION: true';

      const colorLimit = searchParams.get('color_limit');
      if (colorLimit && !isNaN(colorLimit)) {
        filter += ` AND (color_limit >= ${colorLimit} OR color_limit = 0)`;
      }
      requests.map((request) => {
        request.params.filters = filter;
      });
      return algoliaClient.search(requests).then((response) => {
        if (requests.some(({params}) => params.facets && params.facetFilters)) {
          setIsInitialSearch(false);
        }
        return response;
      });
    }
  };

  const applyUserQuery = (query) => {
    setUserQuery(query);
  };

  const validCid = () => {
    return cid.length === 14;
  };

  const multiItemEligible = () => {
    if (iframer !== 'ndx') {
      return false;
    } else if (multiItem !== 'true') {
      return false;
    } else if (!validCid()) {
      return false;
    }
    return true;
  };

  const handleQuantityChange = (qty) => {
    if (qty !== quantity) {
      setQuantity(qty);
      setQuoteQuantityDirty(true);
    }
  };
  const handleFrontColorChange = (colorCount) => {
    if (frontColor !== colorCount) {
      setFrontColor(colorCount);
      if (parseInt(colorCount) === 1) {
        deleteQueryStringParams('quote_fc');
      } else {
        setQuoteQuantityDirty(true);
        setQueryStringParams('quote_fc', colorCount);
      }
    }
    if (
      checkAnyQueryParams('min_order_qty', 'estimated_qty', 'quote_bc') &&
      !checkAnyQueryParams('quote_fc')
    ) {
      setQueryStringParams('quote_fc', colorCount);
    }
  };
  const handleBackColorChange = (colorCount) => {
    if (backColor !== colorCount) {
      setBackColor(colorCount);
      if (parseInt(colorCount) === 0) {
        deleteQueryStringParams('quote_bc');
      } else {
        setQuoteQuantityDirty(true);
        setQueryStringParams('quote_bc', colorCount);
      }
    }
    if (
      checkAnyQueryParams('min_order_qty', 'estimated_qty', 'quote_fc') &&
      !checkAnyQueryParams('quote_bc')
    ) {
      setQueryStringParams('quote_bc', colorCount);
    }
  };
  const onRemoveOrderSize = () => {
    setQuoteQuantityDirty(false);
    setQuantity(default_quote_qty || '');
    setFrontColor('1');
    setBackColor('0');
    deleteQueryStringParams('quote_fc');
    deleteQueryStringParams('quote_bc');
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  const onStateChange = ({uiState, setUiState}) => {
    return instantSearchOnStateChange(uiState, setUiState, algoliaIndexName);
  };

  useEffect(() => {
    const categoryInfo = {
      id: categoryId,
      name: props.category_name
    };
    const pageViewTag = getPageViewEvent(categoryInfo, userQuery);
    if (!pageViewTag.endsWith('custom')) {
      CustomInk.Metrics.sessionPageViewTag(pageViewTag);
    }

    if (userQuery || !categoryId) {
      setSiteWideSearch(true);
    } else {
      setSiteWideSearch(false);
    }
  }, [userQuery, categoryId]);

  // remove this useEffect if we see no rollbars in ~2 weeks
  useEffect(() => {
    const timer = setTimeout(() => {
      if(isInitialSearchRef.current) {
        setIsInitialSearch(false); // force hits to show in case Algolia doesn't make subsequent requests after initial empty one
        rollbar.info('Algolia search request failure', { url: window.location.href})
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    window.onload = (e) => {
      const currentParams = new URLSearchParams(window.location.search);
      if (current_user_internal) {
        const estimatedQty = currentParams.get('estimated_qty');
        const fc = currentParams.get('quote_fc');
        const bc = currentParams.get('quote_bc');
        if (estimatedQty) {
          setQuantity(parseInt(estimatedQty.replace(':', '')));
          setQuoteQuantityDirty(true);
        }
        if (fc) {
          setFrontColor(fc);
          setQuoteQuantityDirty(true);
        }
        if (bc) {
          setBackColor(bc);
          setQuoteQuantityDirty(true);
        }
      }
      if (
        (currentParams.get('min_qty[]') ||
          currentParams.get('min_qty_colors[]')) &&
        filter_groups?.includes('min_qty')
      ) {
        if (document.querySelector('.pc-FiltersGroup--colors.colors')) {
          document.querySelector(
            '.pc-FiltersGroup--colors.colors'
          ).style.display = 'none';
        }
        if (document.querySelector('.pc-FiltersGroup--colors.min_qty_colors')) {
          document.querySelector(
            '.pc-FiltersGroup--colors.min_qty_colors'
          ).style.display = 'flex';
        }
      }
      handleQuickQuoteVisibility(!emptyListings);
    };
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    handleQuickQuoteVisibility(!emptyListings);
  }, [emptyListings]);

  useEffect(() => {
    isInitialSearchRef.current = isInitialSearch;
  }, [isInitialSearch]);

  return (
    <div className="listings" style={{height: '100%', position: 'relative'}}>
      <Provider config={rollbar}>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary rollbar={rollbar}>
            <PigmentThemeProvider>
              <Placeholders
                iframer={iframer}
                isMobile={isMobile}
                firstImage={props.first_image}
              />
              <InstantSearch
                searchClient={searchClient}
                indexName={algoliaIndexName}
                routing={routing}
                onStateChange={onStateChange}
                future={{preserveSharedStateOnUnmount: true}}
                insights={true}>
                <Configure
                  hitsPerPage={42}
                  filters={'status: active OR status: inactive'}
                  facets={['*']}
                  userToken={oeUserToken} // if undefined, algolia will generate an anonymous token
                />
                <div className="listings-wrapper" style={{display: 'none'}}>
                  <ListingsPage
                    categoryId={categoryId}
                    filterGroups={filter_groups}
                    controller={controller}
                    action={action}
                    searchToRefinementListMap={searchToRefinementListMap}
                    algoliaIndexName={algoliaIndexName}
                    applyUserQuery={applyUserQuery}
                    siteWideSearch={siteWideSearch}
                    defaultQuoteQty={default_quote_qty}
                    internalCatalog={internalCatalog}
                    quantity={quantity}
                    frontColor={frontColor}
                    backColor={backColor}
                    currentUserInternal={current_user_internal}
                    quoteQuantityDirty={quoteQuantityDirty}
                    onRemoveOrderSize={onRemoveOrderSize}
                    emptyListings={emptyListings}
                    setEmptyListings={setEmptyListings}
                    iframer={iframer}
                    multiItemEligible={multiItemEligible()}
                    cid={cid}
                    digest={digest}
                    multiItem={multiItem}
                    ydhSettings={ydh_settings}
                    searchParams={searchParams}
                    railsEnv={rails_env}
                    pricingAlgorithm={pricingAlgorithm}
                    algoliaIndexPrefix={algoliaIndexPrefix}
                    isMobile={isMobile}
                    firstImage={props.first_image}
                    algoliaClient={algoliaClient}
                    handleQuantityChange={handleQuantityChange}
                    subcategoryNavigation={subcategory_navigation}
                    designPreviewEnabledCategoryIds={
                      design_preview_enabled_category_ids
                    }
                    isInitialSearch={isInitialSearch}
                  />
                </div>
                {current_user_internal && !emptyListings && (
                  <QuickQuote
                    currentUserInternal={current_user_internal}
                    handleQuantityChange={handleQuantityChange}
                    handleFrontColorChange={handleFrontColorChange}
                    handleBackColorChange={handleBackColorChange}
                    quantity={quantity}
                    frontColor={frontColor}
                    backColor={backColor}
                  />
                )}
              </InstantSearch>
            </PigmentThemeProvider>
          </ErrorBoundary>
        </QueryClientProvider>
      </Provider>
    </div>
  );
};

AlgoliaListingPages.propTypes = {
  action: PropTypes.string,
  category_id: PropTypes.string,
  controller: PropTypes.string,
  filter_groups: PropTypes.array,
  rails_env: PropTypes.string
};

export default AlgoliaListingPages;
