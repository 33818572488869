import React from 'react';
import {Box, LightningIcon, Typography} from '@customink/pigment-react';

const DeliveryRushChip = ({label, superRush}) => {
  return (
    <Box
      data-testid="delivery-rush-chip"
      sx={theme => ({
        borderRadius: '1.25rem',
        background: '#FEEAE3',
        borderColor: 'red',
        p: '0.13rem 0.25rem',
        display: 'inline-flex',
        alignItems: 'center',
        color: theme.palette.red.main
      })}>
      {superRush && (
        <LightningIcon data-testid="delivery-rush-chip-lightning-icon" fontSize="inherit" />
      )}
      <Typography
        data-testid="delivery-rush-chip-label"
        fontSize="0.75rem"
        fontStyle="italic"
        fontWeight={700}
        lineHeight="125%">
        {label}
      </Typography>
    </Box>
  );
};

export default DeliveryRushChip;
