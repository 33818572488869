import { T_SHIRTS_SUBCATEGORIES_IDS, DEFAULT_UNITS_TEST_NUMBER } from '../constants/defaultUnitPrice';

export const isDefaultUnitPriceTestRelevant = (siteWideSearch, categoryId) => {
  return siteWideSearch || T_SHIRTS_SUBCATEGORIES_IDS.includes(Number(categoryId))
}

export const getDefaultUnitPriceTestValue = (siteWideSearch, categoryId) => {
  if (!isDefaultUnitPriceTestRelevant(siteWideSearch, categoryId)) {
    return null;
  }

  return Signalman.featureFlag('default_unit_price_v2');
};

export const getDefaultQuoteQuantity = (categoryId, defaultQuoteQty) => {
  const isDefaultUnitPriceTest = Signalman.featureFlag('default_unit_price_v2');
  if(isDefaultUnitPriceTest === 'test' && T_SHIRTS_SUBCATEGORIES_IDS.includes(parseInt(categoryId, 10))) {
    return DEFAULT_UNITS_TEST_NUMBER;
  } else {
    return defaultQuoteQty;
  }    
}
